import React from 'react';
import ReactDOM from 'react-dom';
import { filter } from 'rxjs/operators';
import { App } from './App';
import { configureSdk } from './configureSdk';
import { query } from './location';
import './index.scss';
import { Console, Context, Inspector, InspectorTabs, Loader, Screen, Storage } from './shared';

const console = new Console();
const storage = new Storage();
const sdk = configureSdk(storage);
const inspector = new Inspector(sdk, storage, console);
const loader = new Loader();
const screen = new Screen(storage);

// adds sdk to global object
(global as any).sdk = sdk;

if (query.instance) {
  document.title = `${query.instance} - ${document.title}`;
}

// catch sdk errors
sdk
  .error$
  .pipe(
    filter(value => !!value),
  )
  .subscribe((err) => {
    console.error(err);
    inspector.changeTab(InspectorTabs.Logs, false);
  });

ReactDOM.render((
    <Context.Provider
      value={{
        sdk,
        console,
        inspector,
        loader,
        screen,
        storage,
      }}
    >
      <App />
    </Context.Provider>
  ),
  document.getElementById('root'),
);


