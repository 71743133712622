import { toChecksumAddress } from 'eth-sdk';
import React from 'react';
import { Code, ContextComponent, Form, TextField, SubmitButton, InspectorTabs } from '../shared';
import {
  templateString,
} from './helpers';

export interface IFormFields {
  refundToken: string;
}

export interface ISubmitFields {
  refundToken: string;
}

export interface IState {
  formFields: IFormFields;
  submitFields: ISubmitFields;
}

const renderCode = ({ refundToken }: ISubmitFields) => `
const refundToken = ${templateString(refundToken)}; // optional

sdk
  .estimateBatch(refundToken)
  .then(batch => console.log('batch', batch))
  .catch(console.error);
  
// subscribe: sdk.state$.batch$
`;

export class EstimateBatch extends ContextComponent<any, IState> {
  private static formFieldsToState(formFields: IFormFields): IState {
    const refundToken = toChecksumAddress(formFields.refundToken);

    return {
      formFields,
      submitFields: {
        refundToken,
      },
    };
  }

  public state: IState = EstimateBatch.formFieldsToState({
    refundToken: null,
  });

  constructor(props: any) {
    super(props);

    this.formChangeHandler = this.formChangeHandler.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
  }

  public render(): React.ReactNode {
    const { formFields, submitFields } = this.state;

    return (
      <div>
        <h1>estimateBatch</h1>
        <div>
          <Code data={renderCode(submitFields)} />
          <Form
            onChange={this.formChangeHandler}
            onSubmit={this.formSubmitHandler}
            fields={formFields}
          >
            <TextField
              label="refundToken"
              name="refundToken"
              buttons={this.tokenFieldButtons}
            />
            <SubmitButton />
          </Form>
        </div>
      </div>
    );
  }

  private formChangeHandler(formFields: IFormFields): void {
    this.setState(
      EstimateBatch
        .formFieldsToState(formFields),
    );
  }

  private formSubmitHandler(): void {
    this.wrapAsync(async () => {
      const { refundToken } = this.state.submitFields;

      const batch = await this
        .sdk
        .estimateBatch(refundToken);

      this.console.log('batch', batch);

      this
        .inspector
        .changeTab(
          InspectorTabs.Batch,
          false,
        );
    });
  }
}
