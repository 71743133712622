import BN from 'bn.js';
import { toWei, Units } from 'eth-sdk';
import React from 'react';
import { Code, ContextComponent, Form, InspectorTabs, SubmitButton, TextField } from '../shared';
import { templateBN } from './helpers';

export interface IFormFields {
  gasPrice: string;
}

export interface ISubmitFields {
  gasPrice: BN;
}

export interface IState {
  formFields: IFormFields;
  submitFields: ISubmitFields;
}

const renderCode = ({ gasPrice }: ISubmitFields) => `
import { toBN } from 'eth-sdk';

const gasPrice = ${templateBN(gasPrice)}; // optional

sdk
  .submitBatch(gasPrice)
  .then(key => console.log('key', key))
  .catch(console.error);
  
// subscribe: sdk.state$.notification$
`;

export class SubmitBatch extends ContextComponent<any, IState> {
  private static formFieldsToState(formFields: IFormFields): IState {
    const gasPrice = toWei(formFields.gasPrice, Units.Gwei);

    return {
      formFields,
      submitFields: {
        gasPrice,
      }
    };
  }

  public state: IState = SubmitBatch.formFieldsToState({
    gasPrice: null,
  });

  constructor(props: any) {
    super(props);

    this.formChangeHandler = this.formChangeHandler.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
  }

  public render(): React.ReactNode {
    const { formFields, submitFields } = this.state;

    return (
      <div>
        <h1>submitBatch</h1>
        <div>
          <Code data={renderCode(submitFields)} />
          <Form
            onChange={this.formChangeHandler}
            onSubmit={this.formSubmitHandler}
            fields={formFields}
          >
            <TextField
              label="gasPrice (GWEI)"
              name="gasPrice"
              buttons={[
                { title: '10', onClick: setFieldValue => setFieldValue('10') },
                { title: '20', onClick: setFieldValue => setFieldValue('20') },
                { title: '30', onClick: setFieldValue => setFieldValue('30') },
              ]}
            />
            <SubmitButton />
          </Form>
        </div>
      </div>
    );
  }

  private formChangeHandler(formFields: IFormFields): void {
    this.setState(
      SubmitBatch
        .formFieldsToState(formFields),
    );
  }

  private formSubmitHandler(): void {
    this.wrapAsync(async () => {
      const { gasPrice } = this.state.submitFields;

      const key = await this
        .sdk
        .submitBatch(gasPrice);

      this.console.log('key', key);

      this
        .inspector
        .changeTab(
          InspectorTabs.Notifications,
          false,
        );
    });
  }
}
