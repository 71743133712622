export enum StorageKeys {
  account = 'account',
  privateKey = 'privateKey',
  inspectorTab = 'inspectorTab',
}

export class Storage {
  private namespace: string;

  public setNamespace(namespace: string): void {
    this.namespace = namespace.toLowerCase();
  }

  public setItem(key: StorageKeys, value: string = null): this {
    const preparedKey = this.prepareKey(key);
    if (value) {
      localStorage.setItem(preparedKey, value);
    } else {
      localStorage.removeItem(preparedKey);
    }

    return this;
  }

  public getItem(key: StorageKeys): string {
    const preparedKey = this.prepareKey(key);
    return localStorage.getItem(preparedKey) || null;
  }

  private prepareKey(key: string): string {
    return this.namespace
      ? `${this.namespace}:${key}`
      : key;
  }
}
