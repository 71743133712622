import { toChecksumAddress } from 'eth-sdk';
import React from 'react';
import { Code, ContextComponent, Form, InspectorTabs, StorageKeys, SubmitButton, TextField } from '../shared';
import { templateString } from './helpers';

export interface IFormFields {
  address: string;
}

export interface ISubmitFields {
  address: string;
}

export interface IState {
  formFields: IFormFields;
  submitFields: ISubmitFields;
}

const renderCode = ({ address }: ISubmitFields) => `
const address = ${templateString(address)}; // optional

sdk
  .createAccount(address)
  .then(account => console.log('account', account))
  .catch(console.error);
`;

export class CreateAccount extends ContextComponent<any, IState> {
  private static formFieldsToState(formFields: IFormFields): IState {
    const address = toChecksumAddress(formFields.address);

    return {
      formFields,
      submitFields: {
        address,
      },
    };
  }

  public state: IState = CreateAccount.formFieldsToState({
    address: null,
  });

  constructor(props: any) {
    super(props);

    this.formChangeHandler = this.formChangeHandler.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
  }

  public render(): React.ReactNode {
    const { formFields, submitFields } = this.state;

    return (
      <div>
        <h1>createAccount</h1>
        <div>
          <Code data={renderCode(submitFields)} />
          <Form
            onChange={this.formChangeHandler}
            onSubmit={this.formSubmitHandler}
            fields={formFields}
          >
            <TextField
              label="address"
              name="address"
            />
            <SubmitButton />
          </Form>
        </div>
      </div>
    );
  }

  private formChangeHandler(formFields: IFormFields): void {
    this.setState(
      CreateAccount
        .formFieldsToState(formFields),
    );
  }

  private formSubmitHandler(): void {
    this.wrapAsync(async () => {
      const { address } = this.state.submitFields;

      const account = await this
        .sdk
        .createAccount(address);

      this.console.log('account', account);

      this.storage.setItem(StorageKeys.account, account.address);

      this
        .inspector
        .changeTab(
          InspectorTabs.Logs,
          false,
        );
    });
  }
}
