export function prepareClassNames(name: any | any[]): string {
  const names: any[] = Array.isArray(name) ? name : [name];

  return names
    .map(name => typeof name === 'string' && name
      ? name
      : null,
    )
    .filter(name => !!name)
    .join(' ');
}

export function shortedAddress(address: string): string {
  return `${address.slice(0, 8)}...${address.slice(-6)}`;
}
