import React from 'react';
import { Input } from '../../components';
import { IContextValue } from '../context';
import { AbstractField, IAbstractProps } from './AbstractField';
import styles from './TextField.module.scss';

export interface IProps extends IAbstractProps {
  placeholder?: string;
}

export class TextField extends AbstractField<IProps> {
  protected renderComponent(contextValue: IContextValue): any {
    const {
      fields,
      changeField,
      submitField,
    } = contextValue;

    const { name, placeholder } = this.props;
    const value = fields[name] || '';

    const onTextChange = (value: string) => {
      changeField(name, value);
    };

    return (
      <Input
        className={styles.root}
        type="text"
        value={value}
        placeholder={placeholder}
        onTextChange={onTextChange}
        onTextSubmit={submitField}
      />
    );
  }
}
