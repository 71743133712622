import { randomAddress, toChecksumAddress, toWei } from 'eth-sdk';
import React from 'react';
import { Code, ContextComponent, Form, InspectorTabs, SubmitButton, TextField } from '../shared';
import { isTrueSet, templateString, templateWei } from './helpers';

export interface IFormFields {
  tokenAddress: string;
  value: string;
  subtractFeesFromValue: string;
  searchByDepositAddress: string;
  channelUUID: string;
  channelDefinitionVersion: string;
  zoneClientEndpoint: string;
}

export interface ISubmitFields {
  tokenAddress: string;
  value: string;
  subtractFeesFromValue: boolean;
  searchByDepositAddress: boolean;
  channelUUID: string;
  channelDefinitionVersion: string;
  zoneClientEndpoint: string;
}

export interface IState {
  formFields: IFormFields;
  submitFields: ISubmitFields;
}

const renderCode = ({ tokenAddress,
                      value,
                      subtractFeesFromValue,
                      searchByDepositAddress,
                      channelUUID,
                      channelDefinitionVersion,
                      zoneClientEndpoint}: ISubmitFields) => `
import { toBN } from 'eth-sdk';

const options = {
  tokenAddress: ${templateString(tokenAddress)}, // if not set will send ETH
  value: ${templateWei(value)},
  subtractFeesFromValue: ${subtractFeesFromValue},
  searchByDepositAddress: ${searchByDepositAddress},
  channelUUID: ${templateString(channelUUID)}, // if not set gets auth key's channel definition
  channelDefinitionVersion: ${templateString(channelDefinitionVersion)}, // if not set gets auth key's channel definition
  zoneEndpointUrl: ${templateString(zoneClientEndpoint)} // if not set gets auth key's channel definition
};

sdk
  .kChannelsWithdraw(options)
  .then(transactionInformation => console.log('transactionInformation', transactionInformation))
  .catch(console.error);
`;

export class KChannelsWithdraw extends ContextComponent<any, IState> {
  private static formFieldsToState(formFields: IFormFields): IState {
    const tokenAddress = toChecksumAddress(formFields.tokenAddress);
    const value = toWei(parseFloat(formFields.value) || 0).toString();
    const subtractFeesFromValue = isTrueSet(formFields.subtractFeesFromValue);
    const searchByDepositAddress = isTrueSet(formFields.searchByDepositAddress);
    const channelUUID = formFields.channelUUID;
    const channelDefinitionVersion = formFields.channelDefinitionVersion;
    const zoneClientEndpoint = formFields.zoneClientEndpoint;

    return {
      formFields,
      submitFields: {
        tokenAddress,
        value,
        subtractFeesFromValue,
        searchByDepositAddress,
        channelUUID,
        channelDefinitionVersion,
        zoneClientEndpoint,
      },
    };
  }

  public state: IState = KChannelsWithdraw.formFieldsToState({
    tokenAddress: null,
    value: null,
    subtractFeesFromValue: null,
    searchByDepositAddress: null,
    channelUUID: null,
    channelDefinitionVersion: null,
    zoneClientEndpoint: null,
  });

  constructor(props: any) {
    super(props);

    this.formChangeHandler = this.formChangeHandler.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
  }

  public render(): React.ReactNode {
    const { formFields, submitFields } = this.state;

    return (
      <div>
        <h1>kChannelsWithdraw</h1>
        <div>
          <Code data={renderCode(submitFields)} />
          <Form
            onChange={this.formChangeHandler}
            onSubmit={this.formSubmitHandler}
            fields={formFields}
          >
            <TextField
              label="options.tokenAddress"
              name="tokenAddress"
              buttons={[
                { title: 'random', onClick: setFieldValue => setFieldValue(randomAddress()) },
                ...this.tokenFieldButtons,
              ]}
            />
            <TextField
              label="options.value"
              name="value"
              buttons={[
                { title: '+ 0.100', onClick: setFieldValue => setFieldValue(0.1, 3) },
                { title: '+ 0.010', onClick: setFieldValue => setFieldValue(0.01, 3) },
                { title: '+ 0.001', onClick: setFieldValue => setFieldValue(0.001, 3) },
              ]}
            />
            <TextField
              label="options.subtractFeesFromValue"
              name="subtractFeesFromValue"
            />
            <TextField
              label="options.searchByDepositAddress"
              name="searchByDepositAddress"
            />
            <TextField
              label="options.channelUUID"
              name="channelUUID"
            />
            <TextField
              label="options.channelDefinitionVersion"
              name="channelDefinitionVersion"
            />
            <TextField
              label="options.zoneClientEndpoint"
              name="zoneClientEndpoint"
            />
            <SubmitButton />
          </Form>
        </div>
      </div>
    );
  }

  private formChangeHandler(formFields: IFormFields): void {
    this.setState(
      KChannelsWithdraw
        .formFieldsToState(formFields),
    );
  }

  private formSubmitHandler(): void {
    this.wrapAsync(async () => {
      const { tokenAddress, value, subtractFeesFromValue, searchByDepositAddress, channelUUID, channelDefinitionVersion, zoneClientEndpoint } = this.state.submitFields;

      const transactionInformation = await this
        .sdk
        .kChannelsWithdraw({
          tokenAddress,
          value,
          subtractFeesFromValue,
          searchByDepositAddress,
          channelUUID,
          channelDefinitionVersion,
          zoneClientEndpoint,
        });

      this.console.log('transactionInformation', transactionInformation);

      this
        .inspector
        .changeTab(
          InspectorTabs.Logs,
          false,
        );
    });
  }
}
