import React from 'react';
import { Button } from '../../components';
import { TContext, IContextValue, Context } from '../context';
import styles from './AbstractField.module.scss';

export interface IAbstractProps {
  label: string;
  name: string;
  disableClear?: boolean;
  buttons?: {
    title: string;
    onClick?: TButtonClickHandler;
  }[];
}

export type TButtonClickHandler = (setFieldValue: (value: any, precision?: number) => void) => void;

export abstract class AbstractField<T extends IAbstractProps = IAbstractProps> extends React.Component<T> {
  static contextType: TContext = Context;
  public context: IContextValue;

  public componentDidMount(): void {
    const { name } = this.props;
    const { initField } = this.context;

    initField(name);
  }

  public render(): any {
    const { label } = this.props;

    return (
      <div className={styles.root}>
        <div>
          {label}
        </div>
        <div>
          <Context.Consumer>
            {contextValue => this.renderComponent(contextValue)}
          </Context.Consumer>
          {this.renderButtons()}
        </div>
      </div>
    );
  }

  protected abstract renderComponent(contextValue: IContextValue): any;

  private renderButtons(): any {
    const { buttons, disableClear } = this.props;
    return (
      <>
        {
          disableClear
            ? null
            : (
              <Button
                title="clear"
                danger={true}
                onClick={this.createButtonHandler(changeField => changeField(''))}
              />
            )
        }
        {
          !buttons
            ? null
            : buttons.map(({ title, onClick }) => (
              <Button
                key={title}
                title={title}
                onClick={onClick
                  ? this.createButtonHandler(onClick)
                  : null
                }
              />
            ))
        }
      </>
    );
  };

  private createButtonHandler(onClick: TButtonClickHandler): () => void {
    const { name } = this.props;
    const { changeField, fields } = this.context;

    return () => {
      onClick((newValue, precision) => {
        let value: string;
        if (
          typeof newValue === 'number' &&
          typeof precision === 'number'
        ) {
          const currentValue = parseFloat(fields[name]) || 0;
          value = `${(currentValue + newValue).toFixed(precision)}`;
        } else {
          value = `${newValue}`;
        }

        changeField(name, value);
      });
    };
  }
}
