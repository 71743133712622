import React from 'react';
import { Code, ContextComponent, IInspectorState, InspectorTabs, prepareClassNames, Button } from '../shared';
import styles from './Inspector.module.scss';

export class Inspector extends ContextComponent<any, IInspectorState> {
  state: IInspectorState = null;

  public componentDidMount(): void {
    this.addSubscriptions(
      this
        .inspector
        .state$
        .subscribe(state => this.setState(state)),
    );
  }

  public render(): any {
    if (!this.state) {
      return null;
    }

    const { logs, notifications } = this.state;

    return (
      <div className={styles.root}>
        <div className={styles.triggers}>
          {this.renderTrigger(InspectorTabs.Logs, logs.length)}
          {this.renderTrigger(InspectorTabs.Notifications, notifications.length)}
          {this.renderTrigger(InspectorTabs.State)}
          {this.renderTrigger(InspectorTabs.Batch)}
        </div>
        {this.renderContent()}
      </div>
    );
  }

  private renderTrigger(tab: InspectorTabs, count = 0): React.ReactNode {
    const {
      tab: stateTab,
    } = this.state;

    const disabled = this.isTabDisabled(tab);

    return (
      <button
        className={prepareClassNames([
          !disabled && tab === stateTab && styles.active,
          disabled && styles.disabled,
        ])}
        onClick={disabled ? () => null : this.inspector.createChangeTabHandler(tab)}
      >
        {tab}{count ? ` (${count})` : ''}
      </button>
    );
  }

  private isTabDisabled(tab: InspectorTabs): boolean {
    let result = true;

    const { logs, notifications, batch } = this.state;

    switch (tab) {
      case InspectorTabs.State:
        result = false;
        break;

      case InspectorTabs.Logs:
        result = !logs.length;
        break;

      case InspectorTabs.Notifications:
        result = !notifications.length;
        break;

      case InspectorTabs.Batch:
        result = !batch;
        break;
    }

    return result;
  }

  private renderLogs(): React.ReactNode {
    const { logs } = this.state;

    return (
      <div className={styles.logs}>
        <Button
          title="clear"
          danger={true}
          onClick={this.inspector.resetLogs}
        />
        {logs.map(({ type, key, args }) => (
          <div key={key}>
            <div>{type}</div>
            <Code
              json={true}
              data={args}
            />
          </div>
        ))}
      </div>
    );
  }

  private renderNotifications(): React.ReactNode {
    const { notifications } = this.state;

    return (
      <div className={styles.notifications}>
        <Button
          title="clear"
          danger={true}
          onClick={this.inspector.resetNotifications}
        />
        {notifications.map(({ type, key, payload }) => (
          <div key={key}>
            <div>{type}</div>
            <Code
              json={true}
              data={payload}
            />
          </div>
        ))}
      </div>
    );
  }

  private renderContent(): React.ReactNode {
    let result: React.ReactNode = null;

    const { tab } = this.state;

    if (!this.isTabDisabled(tab)) {
      switch (tab) {
        case InspectorTabs.Logs:
          result = this.renderLogs();
          break;
        case InspectorTabs.Notifications:
          result = this.renderNotifications();
          break;

        case InspectorTabs.State:
          const { sdk, account, accountAuthKey, authKey } = this.state;
          result = (
            <Code
              json={true}
              data={{
                ...sdk,
                account,
                accountAuthKey,
                authKey,
              }} />
          );
          break;

        case InspectorTabs.Batch:
          const { batch } = this.state;
          result = (
            <Code
              json={true}
              data={batch} />
          );
          break;
      }
    }

    if (result) {
      result = (
        <div className={styles.content}>
          {result}
        </div>
      );
    }

    return result;
  }
}
