import React from 'react';
import { prepareClassNames } from '../utils';
import styles from './Select.module.scss';

export interface ISelectOption {
  label: string;
  value: string;
}

export interface IProps extends React.InputHTMLAttributes<HTMLSelectElement> {
  options: ISelectOption[];
  onValueChange?: (value: string) => any;
}

export class Select extends React.Component<IProps> {
  public render(): any {
    const { className, onValueChange, options, ...props } = this.props;

    let onChange: React.ChangeEventHandler<HTMLSelectElement>;

    if (onValueChange) {
      onChange = (event) => {
        const { target: { value } } = event;
        onValueChange(value);
      };
    }

    return (
      <select
        className={prepareClassNames([
          styles.root,
          className,
        ])}
        onChange={onChange}
        {...props}
      >
        {options.map(({ value, label }) => (
          <option
            key={value}
            value={value}
          >
            {label}
          </option>
        ))}
      </select>
    );
  }
}
