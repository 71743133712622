import React from 'react';
import { Button } from '../../components';
import { TContext, IContextValue, Context } from '../context';

export interface IProps {
  title?: string;
  disabled?: boolean;
}

export class SubmitButton extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    title: 'submit',
  };

  public static contextType: TContext = Context;

  public context: IContextValue;

  constructor(props: any) {
    super(props);

    this.clickHandler = this.clickHandler.bind(this);
  }

  public render(): any {
    const { title, disabled} = this.props;
    return (
      <div>
        <Button
          title={title}
          disabled={disabled}
          onClick={this.clickHandler}
        />
      </div>
    );
  }

  private clickHandler(): void {
    const { submitField } = this.context;
    submitField();
  }
}
