import { Sdk, createSdk, createRopstenSdk, createRinkebySdk, createGoerliSdk, createKovanSdk, createXDaiSdk, createLocalSdk } from 'abridged';
import { Storage, StorageKeys } from './shared';
import { query } from './location';

const {
  REACT_APP_INFURA_PROJECT_ID,
  REACT_APP_PRODUCTION_ENV,
  REACT_APP_DEVELOPMENT_ENV,
  REACT_APP_LOCAL_ENV_BASE_PORT,
  NODE_ENV,
} = process.env;

const SDK_STORAGE_VERSION = 'beta.1';

enum SdkEnvs {
  Mainnet = 'mainnet',
  Ropsten = 'ropsten',
  Rinkeby = 'rinkeby',
  Goerli = 'goerli',
  Kovan = 'kovan',
  XDai = 'xdai',
  Local = 'local',
}

function prepareSdkEnv(env: any, defaultValue: SdkEnvs): SdkEnvs {
  let result: SdkEnvs = null;

  if (env && Object.values(SdkEnvs).includes(env)) {
    result = env;
  }

  return result || defaultValue;
}

let sdkEnv = NODE_ENV === 'production'
  ? prepareSdkEnv(REACT_APP_PRODUCTION_ENV, SdkEnvs.Goerli)
  : prepareSdkEnv(REACT_APP_DEVELOPMENT_ENV, SdkEnvs.Goerli);

sdkEnv = prepareSdkEnv(
  query.env,
  sdkEnv,
);

export function configureSdk(storage: Storage): Sdk {
  let result: Sdk;

  storage.setNamespace(`${sdkEnv}${SDK_STORAGE_VERSION}:${query.instance || 'default'}`);

  const account = storage.getItem(StorageKeys.account);
  const privateKey = storage.getItem(StorageKeys.privateKey);

  const options: Sdk.IOptions = {
    authKeyModule: {
      privateKey,
    },
  };

  switch (sdkEnv) {
    case SdkEnvs.Mainnet:
      result = createSdk({
        ...options,
        queryProviderEndpoint: REACT_APP_INFURA_PROJECT_ID
          ? `https://mainnet.infura.io/v3/${REACT_APP_INFURA_PROJECT_ID}`
          : 'https://mainnet.infura.io',
      });
      break;

    case SdkEnvs.Ropsten:
      result = createRopstenSdk({
        ...options,
        queryProviderEndpoint: REACT_APP_INFURA_PROJECT_ID
          ? `https://ropsten.infura.io/v3/${REACT_APP_INFURA_PROJECT_ID}`
          : 'https://ropsten.infura.io',
      });
      break;

    case SdkEnvs.Rinkeby:
      result = createRinkebySdk({
        ...options,
        queryProviderEndpoint: REACT_APP_INFURA_PROJECT_ID
          ? `https://rinkeby.infura.io/v3/${REACT_APP_INFURA_PROJECT_ID}`
          : 'https://rinkeby.infura.io',
      });
      break;

    case SdkEnvs.Goerli:
      result = createGoerliSdk({
        ...options,
        queryProviderEndpoint: REACT_APP_INFURA_PROJECT_ID
          ? `https://goerli.infura.io/v3/${REACT_APP_INFURA_PROJECT_ID}`
          : 'https://goerli.infura.io',
      });
      break;

    case SdkEnvs.Kovan:
      result = createKovanSdk({
        ...options,
        queryProviderEndpoint: REACT_APP_INFURA_PROJECT_ID
          ? `https://kovan.infura.io/v3/${REACT_APP_INFURA_PROJECT_ID}`
          : 'https://kovan.infura.io',
      });
      break;

    case SdkEnvs.XDai:
      result = createXDaiSdk({
        ...options,
        queryProviderEndpoint: 'https://dai.poa.network',
      });
      break;

    case SdkEnvs.Local:
      result = createLocalSdk(options, parseInt(REACT_APP_LOCAL_ENV_BASE_PORT));
      break;
  }

  if (!privateKey) {
    result
      .exportPrivateKey()
      .then(privateKey => storage.setItem(StorageKeys.privateKey, privateKey))
      .catch(() => null);
  }

  if (account) {
    result
      .createAccount(account)
      .catch(() => null);
  }

  return result;
}
