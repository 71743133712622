import React from 'react';
import { Code, ContextComponent, Form, InspectorTabs, SubmitButton } from '../shared';

const renderCode = () => `
sdk
  .kChannelsAuthenticate()
  .then(jwt => console.log('jwt', jwt))
  .catch(console.error);
`;

export class KChannelsAuthenticate extends ContextComponent {
  constructor(props: any) {
    super(props);

    this.formSubmitHandler = this.formSubmitHandler.bind(this);
  }

  public render(): React.ReactNode {
    return (
      <div>
        <h1>kChannelsAuthenticate</h1>
        <div>
          <Code data={renderCode()} />
          <Form onSubmit={this.formSubmitHandler}>
            <SubmitButton />
          </Form>
        </div>
      </div>
    );
  }

  private formSubmitHandler(): void {
    this.wrapAsync(async () => {
      const jwt = await this
        .sdk
        .kChannelsAuthenticate();

      this.console.log('jwt', jwt);

      this
        .inspector
        .changeTab(
          InspectorTabs.Logs,
          false,
        );
    });
  }
}
