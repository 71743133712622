import React from 'react';
import { Code, ContextComponent, Form, InspectorTabs, SubmitButton } from '../shared';

const renderCode = () => `
sdk
  .kChannelsCreate()
  .then(channelDefinition => console.log('channelDefinition', channelDefinition))
  .catch(console.error);
`;

export class KChannelsCreate extends ContextComponent {
  constructor(props: any) {
    super(props);

    this.formSubmitHandler = this.formSubmitHandler.bind(this);
  }

  public render(): React.ReactNode {
    return (
      <div>
        <h1>kChannelsCreate</h1>
        <div>
          <Code data={renderCode()} />
          <Form onSubmit={this.formSubmitHandler}>
            <SubmitButton />
          </Form>
        </div>
      </div>
    );
  }

  private formSubmitHandler(): void {
    this.wrapAsync(async () => {
      const channelDefinition = await this
        .sdk
        .kChannelsCreate();

      this.console.log('channelDefinition', channelDefinition);

      this
        .inspector
        .changeTab(
          InspectorTabs.Logs,
          false,
        );
    });
  }
}
