"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var AccountAuthKeyTypes;

(function (AccountAuthKeyTypes) {
  AccountAuthKeyTypes["Removed"] = "Removed";
  AccountAuthKeyTypes["Restricted"] = "Restricted";
  AccountAuthKeyTypes["Owner"] = "Owner";
})(AccountAuthKeyTypes = exports.AccountAuthKeyTypes || (exports.AccountAuthKeyTypes = {}));

var AccountEventNames;

(function (AccountEventNames) {
  AccountEventNames["AccountDeployed"] = "AccountDeployed";
  AccountEventNames["AccountDepositUpdated"] = "AccountDepositUpdated";
  AccountEventNames["AccountDepositMerged"] = "AccountDepositMerged";
  AccountEventNames["AccountDepositWithdrawn"] = "AccountDepositWithdrawn";
  AccountEventNames["AccountDepositWithdrawalRequested"] = "AccountDepositWithdrawalRequested";
  AccountEventNames["AccountDepositWithdrawalCanceled"] = "AccountDepositWithdrawalCanceled";
  AccountEventNames["AccountAuthKeyUpdated"] = "AccountAuthKeyUpdated";
  AccountEventNames["AccountTransactionExecuted"] = "AccountTransactionExecuted";
  AccountEventNames["AccountPaymentChannelCommitted"] = "AccountPaymentChannelCommitted";
})(AccountEventNames = exports.AccountEventNames || (exports.AccountEventNames = {}));

var TransactionStates;

(function (TransactionStates) {
  TransactionStates["Unknown"] = "Unknown";
  TransactionStates["Pending"] = "Pending";
  TransactionStates["Succeeded"] = "Succeeded";
  TransactionStates["Failed"] = "Failed";
})(TransactionStates = exports.TransactionStates || (exports.TransactionStates = {}));

var NotificationTypes;

(function (NotificationTypes) {
  NotificationTypes["AccountAuthKeyUpdated"] = "AccountAuthKeyUpdated";
  NotificationTypes["AccountDepositUpdated"] = "AccountDepositUpdated";
  NotificationTypes["AccountEventProcessed"] = "AccountEventProcessed";
  NotificationTypes["AccountUpdated"] = "AccountUpdated";
  NotificationTypes["PaymentChannelUpdated"] = "PaymentChannelUpdated";
  NotificationTypes["RelayedTransactionUpdated"] = "RelayedTransactionUpdated";
  NotificationTypes["KChannelsTransaction"] = "KChannelsTransaction";
  NotificationTypes["KChannelsWebSocketConnectionClosed"] = "KChannelsWebSocketConnectionClosed";
  NotificationTypes["KChannelsWebSocketConnectionOpened"] = "KChannelsWebSocketConnectionOpened";
})(NotificationTypes = exports.NotificationTypes || (exports.NotificationTypes = {}));

var RelayedTransactionStates;

(function (RelayedTransactionStates) {
  RelayedTransactionStates["Queued"] = "Queued";
  RelayedTransactionStates["Reverted"] = "Reverted";
  RelayedTransactionStates["Sending"] = "Sending";
  RelayedTransactionStates["Sent"] = "Sent";
})(RelayedTransactionStates = exports.RelayedTransactionStates || (exports.RelayedTransactionStates = {}));

var EIP712SignaturePrimaryTypes;

(function (EIP712SignaturePrimaryTypes) {
  EIP712SignaturePrimaryTypes["AuthenticationChallenge"] = "AuthenticationChallenge";
  EIP712SignaturePrimaryTypes["ChannelDefinition"] = "ChannelDefinition";
  EIP712SignaturePrimaryTypes["Transaction"] = "Transaction";
  EIP712SignaturePrimaryTypes["TransactionSummary"] = "TransactionSummary";
})(EIP712SignaturePrimaryTypes = exports.EIP712SignaturePrimaryTypes || (exports.EIP712SignaturePrimaryTypes = {}));

var KChannelsJSONRpcMethodNames;

(function (KChannelsJSONRpcMethodNames) {
  KChannelsJSONRpcMethodNames["AbandonTransactions"] = "abandon_transactions";
  KChannelsJSONRpcMethodNames["CompleteTransaction"] = "complete_transaction";
  KChannelsJSONRpcMethodNames["CreateChannelDefinition"] = "create_channel_definition";
  KChannelsJSONRpcMethodNames["CreateNewTransaction"] = "create_new_transaction";
  KChannelsJSONRpcMethodNames["FailTransaction"] = "fail_transaction";
  KChannelsJSONRpcMethodNames["ProcessTransaction"] = "process_transaction";
  KChannelsJSONRpcMethodNames["UpdateAndCompleteChannelDefinition"] = "update_and_complete_channel_definition";
})(KChannelsJSONRpcMethodNames = exports.KChannelsJSONRpcMethodNames || (exports.KChannelsJSONRpcMethodNames = {}));

var KChannelsWebSocketTypes;

(function (KChannelsWebSocketTypes) {
  KChannelsWebSocketTypes["Channel"] = "channel";
  KChannelsWebSocketTypes["Transaction"] = "transaction";
})(KChannelsWebSocketTypes = exports.KChannelsWebSocketTypes || (exports.KChannelsWebSocketTypes = {}));

exports.EIP712Domain = [{
  name: 'name',
  type: 'string'
}, {
  name: 'version',
  type: 'uint256'
}, {
  name: 'chainId',
  type: 'uint256'
}, {
  name: 'verifyingContract',
  type: 'address'
}, {
  name: 'salt',
  type: 'bytes32'
}];
exports.KChannelsAuthenticationChallenge = [{
  name: 'text',
  type: 'string'
}, {
  name: 'client_unpredictable_number',
  type: 'uint256'
}, {
  name: 'unpredictable_number',
  type: 'uint256'
}, {
  name: 'client_ip',
  type: 'string'
}, {
  name: 'issued_at',
  type: 'uint256'
}, {
  name: 'expires_at',
  type: 'uint256'
}, {
  name: 'signing_identity',
  type: 'address'
}];
exports.KChannelsChannelDefinition = [{
  name: 'channel_uuid',
  type: 'string'
}, {
  name: 'definition_version',
  type: 'uint256'
}, {
  name: 'channel_rating_id',
  type: 'string'
}, {
  name: 'zone_address',
  type: 'address'
}, {
  name: 'owner_address',
  type: 'address'
}, {
  name: 'deposit_address',
  type: 'address'
}, {
  name: 'validator_address',
  type: 'address'
}, {
  name: 'sender_address_list',
  type: 'address[]'
}, {
  name: 'initial_state_hash',
  type: 'bytes32'
}];
exports.KChannelsChannelAssetDefinition = [{
  name: 'smart_contract',
  type: 'address'
}, {
  name: 'value',
  type: 'uint256'
}];
exports.KChannelsChannelStateDefinition = [{
  name: 'nonce',
  type: 'uint256'
}, {
  name: 'channel_asset_list',
  type: 'ChannelAsset[]'
}];
exports.KChannelsTransactionValueDefinition = [{
  name: 'smart_contract',
  type: 'address'
}, {
  name: 'value',
  type: 'int256'
}, {
  name: 'kind',
  type: 'string'
}];
exports.KChannelsTransactionPartyDefinition = [{
  name: 'nonce',
  type: 'uint256'
}, {
  name: 'state_hash',
  type: 'bytes32'
}, {
  name: 'timestamp',
  type: 'uint256'
}, {
  name: 'channel_definition',
  type: 'ChannelDefinition'
}, {
  name: 'fee_list',
  type: 'TransactionValue[]'
}];
exports.KChannelsTransactionDefinition = [{
  name: 'request_uuid',
  type: 'string'
}, {
  name: 'reference_data',
  type: 'string'
}, {
  name: 'value_list',
  type: 'TransactionValue[]'
}, {
  name: 'sender_party',
  type: 'TransactionParty'
}, {
  name: 'recipient_party',
  type: 'TransactionParty'
}];
exports.KChannelsTransactionSummaryDefinition = [{
  name: 'request_uuid',
  type: 'string'
}, {
  name: 'channel_uuid',
  type: 'string'
}, {
  name: 'definition_version',
  type: 'uint256'
}, {
  name: 'client_signer_address',
  type: 'address'
}, {
  name: 'zone_signer_address',
  type: 'address'
}, {
  name: 'final_state_hash',
  type: 'bytes32'
}, {
  name: 'external_tx_reference',
  type: 'bytes32'
}, {
  name: 'recipient_address',
  type: 'address'
}, {
  name: 'peer_last_seen_state_hash',
  type: 'bytes32'
}, {
  name: 'timestamp',
  type: 'uint256'
}, {
  name: 'value_list',
  type: 'TransactionValue[]'
}];
exports.KChannelsTransactionMetadataDefinition = [{
  name: 'request_uuid',
  type: 'string'
}, {
  name: 'channel_uuid',
  type: 'string'
}, {
  name: 'definition_version',
  type: 'uint256'
}, {
  name: 'reversal_nonce',
  type: 'uint256'
}, {
  name: 'external_tx_reference_list',
  type: 'string[]'
}];