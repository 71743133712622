import { Sdk } from 'abridged';
import { ContractNames, getContractAddress } from 'consumable-token';
import React from 'react';
import { Subscription } from 'rxjs';
import { Console, Inspector, Loader, Screen, Storage } from '../services';
import { Context } from './Context';
import { IContextValue } from './interfaces';
import { TContext } from './types';

export abstract class ContextComponent<P = any, S = any> extends React.Component<P, S> {
  static contextType: TContext = Context;

  public context: IContextValue;

  private subscriptions: Subscription[] = [];

  public componentWillUnmount(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  protected get sdk(): Sdk {
    return this.context.sdk;
  }

  protected get console(): Console {
    return this.context.console;
  }

  protected get inspector(): Inspector {
    return this.context.inspector;
  }

  protected get loader(): Loader {
    return this.context.loader;
  }

  protected get screen(): Screen {
    return this.context.screen;
  }

  protected get storage(): Storage {
    return this.context.storage;
  }

  protected get token(): string {
    const { networkVersion } = this.sdk.state;

    return networkVersion
      ? getContractAddress(
        ContractNames.ConsumableToken,
        networkVersion,
      )
      : null;
  }

  protected get tokenFieldButtons(): any[] {
    const result: any[] = [];

    const token = this.token;

    if (token) {
      result.push(
        { title: 'use consumable token', onClick: setFieldValue => setFieldValue(token) },
      );
    }

    return result;
  }

  protected addSubscriptions(...subscriptions: Subscription[]): this {
    this.subscriptions = [
      ...this.subscriptions,
      ...subscriptions,
    ];
    return this;
  }

  protected wrapAsync(inner: () => Promise<any>): void {
    this.loader.show();

    const done = () => {
      this.loader.hide();
    };

    try {
      inner()
        .then(() => done())
        .catch(() => done());
    } catch (err) {
      done();
    }
  }
}
