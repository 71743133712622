import { BehaviorSubject } from 'rxjs';
import { Storage } from './Storage';

export interface IScreenState {
  screen: string;
}

export class Screen {
  private static STORAGE_KEY = 'screen';

  public state$: BehaviorSubject<IScreenState>;

  constructor(
    private storage: Storage,
  ) {
    let { hash } = document.location;
    if (hash) {
      hash = hash.substr(2);
    }

    this.state$ = new BehaviorSubject({
      screen: hash,
    });
  }

  public get state(): IScreenState {
    return this.state$.getValue();
  }

  public open(screen: string): void {
    this.state$.next({
      screen,
    });
  }

  public createOpenHandler(screen: string): () => void {
    return () => {
      this.open(screen);
    };
  }
}
