import { toEth } from 'eth-sdk';
import React from 'react';
import { ContextComponent, CopyTrigger, IInspectorState, shortedAddress } from '../shared';
import styles from './Summary.module.scss';

export class Summary extends ContextComponent<any, IInspectorState> {
  state: IInspectorState = null;

  public componentDidMount(): void {
    this.addSubscriptions(
      this
        .inspector
        .state$
        .subscribe(state => this.setState(state)),
    );
  }

  public render(): any {
    if (!this.state) {
      return null;
    }

    const { account, accountAuthKey, accountBalance, accountDeposit, authKey, kChannelsBalance } = this.state;

    return (
      <div className={styles.root}>
        {!account || !account.address ? null : (
          <div className={styles.section}>
            <div>account</div>
            <div>
              <CopyTrigger
                title={shortedAddress(account.address)}
                text={account.address}
              />
            </div>
            <div>
              {
                account.deployed
                  ? 'deployed'
                  : 'not deployed yet'
              }
            </div>
          </div>
        )}
        {!accountAuthKey || !accountAuthKey.type ? null : (
          <div className={styles.section}>
            <div>account auth key</div>
            <div>
              {accountAuthKey.type}
            </div>
            <div>
              {
                accountAuthKey.deployed
                  ? 'deployed'
                  : 'not deployed yet'
              }
            </div>
          </div>
        )}
        {!accountBalance || !account ? null : (
          <div className={styles.section}>
            <div>account balance</div>
            <CopyTrigger
              title={toEth(accountBalance)}
              text={account.address}
            />
            <div>ETH</div>
          </div>
        )}
        {!accountDeposit || !account ? null : (
          <div className={styles.section}>
            <div>account deposit</div>
            <CopyTrigger
              title={toEth(accountDeposit)}
              text={account.externalDepositAddress}
            />
            <div>ETH</div>
          </div>
        )}
        {!kChannelsBalance || !kChannelsBalance ? null : (
          <div className={styles.section}>
            <div>kChannels Balance</div>
            <CopyTrigger
              title={toEth(kChannelsBalance)}
              text={toEth(kChannelsBalance)}
            />
            <div>ETH</div>
          </div>
        )}
        {!authKey || !authKey.address ? null : (
          <div className={styles.section}>
            <div>auth key</div>
            <div>
              <CopyTrigger
                title={shortedAddress(authKey.address)}
                text={authKey.address}
              />
            </div>
            {!accountAuthKey || !accountAuthKey.type
              ? (
                <div className={styles.danger}>
                  disconnected
                </div>
              )
              : (
                <div>
                  connected
                </div>
              )
            }

          </div>
        )}
      </div>
    );
  }
}
