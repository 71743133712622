import { AccountAuthKeyTypes } from 'abridged';
import { randomAddress, toChecksumAddress } from 'eth-sdk';
import React from 'react';
import { Code, ContextComponent, Form, TextField, SelectField, SubmitButton, InspectorTabs } from '../shared';
import {
  templateString,
} from './helpers';

export interface IFormFields {
  authKey: string;
  type: string;
}

export interface ISubmitFields {
  authKey: string;
  type: AccountAuthKeyTypes;
}

export interface IState {
  formFields: IFormFields;
  submitFields: ISubmitFields;
  disabled: boolean;
}

const renderCode = ({ authKey, type }: ISubmitFields) => `
import { AccountAuthKeyTypes } from 'abridged';

const options = {
  authKey: ${templateString(authKey)},
  type: AccountAuthKeyTypes.${type},
};

sdk
  .batchSetAccountAuthKey(options)
  .then(batch => console.log('batch', batch))
  .catch(console.error);
  
// subscribe: sdk.state$.batch$
`;

export class BatchSetAccountAuthKey extends ContextComponent<any, IState> {
  private static formFieldsToState(formFields: IFormFields): IState {
    const authKey = toChecksumAddress(formFields.authKey);

    return {
      formFields,
      submitFields: {
        authKey,
        type: formFields.type as any,
      },
      disabled: !authKey,
    };
  }

  public state: IState = BatchSetAccountAuthKey.formFieldsToState({
    authKey: null,
    type: AccountAuthKeyTypes.Owner,
  });

  constructor(props: any) {
    super(props);

    this.formChangeHandler = this.formChangeHandler.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
  }

  public render(): React.ReactNode {
    const { formFields, submitFields, disabled } = this.state;

    return (
      <div>
        <h1>batchSetAccountAuthKey</h1>
        <div>
          <Code data={renderCode(submitFields)} />
          <Form
            onChange={this.formChangeHandler}
            onSubmit={this.formSubmitHandler}
            fields={formFields}
          >
            <TextField
              label="options.authKey"
              name="authKey"
              buttons={[
                { title: 'random', onClick: setFieldValue => setFieldValue(randomAddress()) },
              ]}
            />
            <SelectField
              label="options.type"
              name="type"
              disableClear={true}
              options={Object.values(AccountAuthKeyTypes).reverse().map(value => ({
                value,
                label: value,
              }))}
            />
            <SubmitButton disabled={disabled} />
          </Form>
        </div>
      </div>
    );
  }

  private formChangeHandler(formFields: IFormFields): void {
    this.setState(
      BatchSetAccountAuthKey
        .formFieldsToState(formFields),
    );
  }

  private formSubmitHandler(): void {
    this.wrapAsync(async () => {
      const { authKey, type } = this.state.submitFields;

      const batch = await this
        .sdk
        .batchSetAccountAuthKey({
          authKey,
          type,
        });

      this.console.log('batch', batch);

      this
        .inspector
        .changeTab(
          InspectorTabs.Batch,
          false,
        );
    });
  }
}
