import React from 'react';
import { IContextValue, Context } from '../context';

export type TFields = { [key: string]: any };

export interface IProps {
  fields?: TFields;
  onChange?: (fields: TFields) => void;
  onSubmit?: (fields: TFields) => void;
}

export class Form<T = { [key: string]: any }> extends React.Component<IProps, IContextValue> {
  public static defaultProps: Partial<IProps> = {
    fields: {},
  };

  public state: IContextValue = null;

  constructor(props: IProps) {
    super(props);

    const { fields } = props;

    this.state = {
      fields,
      initField: this.initFieldHandler.bind(this),
      changeField: this.changeFieldHandler.bind(this),
      submitField: this.submitFieldHandler.bind(this),
    };
  }

  public render(): any {
    const { children } = this.props;

    return (
      <Context.Provider value={this.state}>
        <div>
          {children}
        </div>
      </Context.Provider>
    );
  }

  private initFieldHandler(name: string): void {
    const { fields } = this.state;
    if (typeof fields[name] === 'undefined') {
      fields[name] = null;
    }
  }

  private changeFieldHandler(name: string, value: any): void {
    const { onChange } = this.props;
    let { fields } = this.state;

    fields = {
      ...fields,
      [name]: value,
    };

    this.setState({
      fields,
    });

    if (onChange) {
      onChange(fields);
    }
  }

  private submitFieldHandler(): void {
    const { onSubmit } = this.props;
    const { fields } = this.state;

    if (onSubmit) {
      onSubmit(fields);
    }
  }
}
