import 'highlight.js/styles/a11y-dark.css';
import 'highlight.js/lib/languages/javascript';
import 'highlight.js/lib/languages/json';
import BN from 'bn.js';

import React from 'react';
import Highlight from 'react-highlight';
import {toEth} from 'eth-sdk';
import { prepareClassNames } from '../utils';
import styles from './Code.module.scss';

export interface IProps {
  className?: string;
  json?: boolean;
  data: any;
}

export class Code extends React.Component<IProps> {
  private static jsonReplacer(this: { [key: string]: any }, key: string, value: any): any {
    const data = this[key];

    if (BN.isBN(data)) {
      let text: string = data.toString(10);

      if (text.length > 12) {
        text = `${text} or ${toEth(data)} in ETH`;
      }

      value = `BN: ${text}`;
    } else if (data instanceof Date) {
      value = `Date: ${data.toISOString()}`;
    } else if (data instanceof Error) {
      value = `${data.message || data}`;
    }

    return value;
  }

  public render(): any {
    const { json, data } = this.props;
    let code: string = '';

    if (typeof data === 'string') {
      code = data;
    } else {
      code = JSON.stringify(data, Code.jsonReplacer, 2);
    }

    let empty = false;
    return (
      <div className={prepareClassNames([
        styles.root,
        json && styles.json,
        !json && styles.javascript,
      ])}>
        <Highlight
          language={json ? 'json' : 'javascript'}
        >
          {code
            .trim()
            .split('\n')
            .filter((line) => {
              let result = true;
              if (!line.trim()) {
                if (empty) {
                  result = false;
                } else {
                  empty = true;
                }
              } else {
                empty = false;
              }
              return result;
            })
            .join('\n')}
        </Highlight>
      </div>
    );
  }
}
