import React from 'react';
import { toNumberString } from 'eth-sdk';
import { ContextComponent, CopyTrigger, IInspectorState, shortedAddress } from '../shared';
import { query } from '../location';
import styles from './StatusBar.module.scss';

export class StatusBar extends ContextComponent<any, IInspectorState> {
  state: IInspectorState = null;

  public componentDidMount(): void {
    this.addSubscriptions(
      this
        .inspector
        .state$
        .subscribe(state => this.setState(state)),
    );
  }

  public render(): any {
    let content: React.ReactNode = null;

    if (this.state) {
      const { sdk, gasPrice } = this.state;

      const token = this.token;

      content = (
        <>
          {!query.instance ? null : (
            <div className={styles.instance}>
              instance <span>{query.instance}</span>
            </div>
          )}
          {!sdk || !sdk.networkVersion ? null : (
            <div>
              network <span>{sdk.networkName}</span> <span>{`#${sdk.networkVersion}`}</span>
            </div>
          )}
          {!gasPrice ? null : (
            <div className={styles.section}>
              gas price <span>{toNumberString(gasPrice, {
              shift: -9,
              precision: 6,
            })}</span> <span>GWEI</span>
            </div>
          )}
          {!token ? null : (
            <div>
              consumable token <CopyTrigger
              title={shortedAddress(token)}
              text={token}
            />
            </div>
          )}
        </>
      );
    }

    return (
      <div
        className={styles.root}
      >
        {content}
      </div>
    );
  }

}
