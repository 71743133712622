import React from 'react';
import { ContextComponent } from '../shared';

export interface IState {
  items: any[];
}

export class ToolBar extends ContextComponent<any, IState> {
  public state: IState = {
    items: [],
  };

  public componentDidMount(): void {
    //
  }

  public render(): any {
    return (
      <>
        <div>
          ToolBar
        </div>
      </>
    );
  }
}
