"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var Sdk_1 = require("./Sdk");

function createSdk() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Sdk_1.Sdk.create(Sdk_1.Sdk.Envs.Mainnet, options);
}

exports.createSdk = createSdk;

function createRopstenSdk() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Sdk_1.Sdk.create(Sdk_1.Sdk.Envs.Ropsten, options);
}

exports.createRopstenSdk = createRopstenSdk;

function createRinkebySdk() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Sdk_1.Sdk.create(Sdk_1.Sdk.Envs.Rinkeby, options);
}

exports.createRinkebySdk = createRinkebySdk;

function createGoerliSdk() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Sdk_1.Sdk.create(Sdk_1.Sdk.Envs.Goerli, options);
}

exports.createGoerliSdk = createGoerliSdk;

function createKovanSdk() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Sdk_1.Sdk.create(Sdk_1.Sdk.Envs.Kovan, options);
}

exports.createKovanSdk = createKovanSdk;

function createXDaiSdk() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Sdk_1.Sdk.create(Sdk_1.Sdk.Envs.XDai, options);
}

exports.createXDaiSdk = createXDaiSdk;

function createLocalSdk() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var basePort = arguments.length > 1 ? arguments[1] : undefined;
  return Sdk_1.Sdk.create(null, options, basePort);
}

exports.createLocalSdk = createLocalSdk;