import React from 'react';
import { Code, ContextComponent, Form, TextField, SubmitButton, InspectorTabs } from '../shared';
import {
  templateString,
} from './helpers';

export interface IFormFields {
  message: string;
}

export interface ISubmitFields {
  message: string;
}

export interface IState {
  formFields: IFormFields;
  submitFields: ISubmitFields;
  disabled: boolean;
}

const renderCode = ({ message }: ISubmitFields) => `
const message = ${templateString(message)};

sdk
  .signMessage(message)
  .then(signature => console.log('signature', signature))
  .catch(console.error);
`;

export class SignMessage extends ContextComponent<any, IState> {
  private static formFieldsToState(formFields: IFormFields): IState {
    const message = formFields.message;

    return {
      formFields,
      submitFields: {
        message
      },
      disabled: !message,
    };
  }

  public state: IState = SignMessage.formFieldsToState({
    message: null,
  });

  constructor(props: any) {
    super(props);

    this.formChangeHandler = this.formChangeHandler.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
  }

  public render(): React.ReactNode {
    const { formFields, submitFields, disabled } = this.state;

    return (
      <div>
        <h1>signMessage</h1>
        <div>
          <Code data={renderCode(submitFields)} />
          <Form
            onChange={this.formChangeHandler}
            onSubmit={this.formSubmitHandler}
            fields={formFields}
          >
            <TextField
              label="message"
              name="message"
            />
            <SubmitButton disabled={disabled} />
          </Form>
        </div>
      </div>
    );
  }

  private formChangeHandler(formFields: IFormFields): void {
    this.setState(
      SignMessage
        .formFieldsToState(formFields),
    );
  }

  private formSubmitHandler(): void {
    this.wrapAsync(async () => {
      const { message } = this.state.submitFields;

      const signature = await this
        .sdk
        .signMessage(message);

      this.console.log('signature', signature);

      this
        .inspector
        .changeTab(
          InspectorTabs.Logs,
          false,
        );
    });
  }
}
