import { parse } from 'querystring';

export interface IQuery {
  env: string;
  instance: any;
}

export const query: IQuery = {
  instance: null,
  env: null,
  ...((parse(document.location.search.slice(1)) as any) || {}),
};
