"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var eth_sdk_1 = require("eth-sdk");

var counter = Date.now();

function getUniqueHex() {
  return eth_sdk_1.toHex(counter += 1);
}

exports.getUniqueHex = getUniqueHex;