import React from 'react';
import { ContextComponent, ILoaderState } from '../shared';
import styles from './Loader.module.scss';

export class Loader extends ContextComponent<any, ILoaderState> {
  public state = {
    show: true,
  };

  public componentDidMount(): void {
    this.addSubscriptions(
      this
        .loader
        .state$
        .subscribe(state => this.setState(state)),
    );
  }

  public render(): any {
    const { show } = this.state;

    return !show ? null : (
      <div
        className={styles.root}
      >
        <svg width="40" height="40" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#27E270">
          <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)" strokeWidth="2">
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite" />
              </path>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
