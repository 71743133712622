import BN from 'bn.js';
import { BehaviorSubject, from, of } from 'rxjs';
import { filter, switchMap, mergeMap, map } from 'rxjs/operators';
import { Sdk, IAccount, IAccountAuthKey, IAuthKey, IBatch, INotification, getUniqueHex, IAccountDeposit } from 'abridged';
import { Console, IConsoleItem } from './Console';
import { Storage, StorageKeys } from './Storage';

export enum InspectorTabs {
  Logs = 'logs',
  Notifications = 'notifications',
  State = 'state$',
  Batch = 'batch$',
}

export interface IInspectorState {
  tab: InspectorTabs;
  logs: IIInspectorLog[];
  sdk: Sdk.IState;
  notifications: IIInspectorNotification[];
  account: IAccount;
  accountAuthKey: IAccountAuthKey;
  accountBalance: BN;
  accountDeposit: BN;
  authKey: IAuthKey;
  batch: IBatch;
  kChannelsBalance: BN;
  gasPrice: BN;
}

export interface IIInspectorLog extends IConsoleItem {
  key: string
}

export interface IIInspectorNotification extends INotification {
  key: string
}

export class Inspector {
  public state$: BehaviorSubject<IInspectorState>;

  constructor(
    sdk: Sdk,
    private storage: Storage,
    consoleService: Console,
  ) {

    this.resetLogs = this.resetLogs.bind(this);
    this.resetNotifications = this.resetNotifications.bind(this);

    this.state$ = new BehaviorSubject({
      tab: storage.getItem(StorageKeys.inspectorTab) as any,
      logs: [],
      notifications: [],
      sdk: null,
      account: null,
      accountAuthKey: null,
      accountBalance: null,
      accountDeposit: null,
      authKey: null,
      batch: null,
      kChannelsBalance: null,
      gasPrice: null,
    });

    consoleService
      .item$
      .pipe(
        filter(value => !!value),
      )
      .subscribe((item) => this.updateState({
        logs: [{
          ...item,
          key: getUniqueHex(),
        },
          ...this.state.logs,
        ],
      }));

    const { state$ } = sdk;

    state$
      .subscribe(sdk => this.updateState({
        sdk,
      }));

    state$
      .notification$
      .pipe(
        filter(value => !!value),
      )
      .subscribe(item => this.updateState({
        notifications: [{
          ...item,
          key: getUniqueHex(),
        },
          ...this.state.notifications,
        ],
      }));

    state$
      .account$
      .subscribe(account => this.updateState({
        account,
      }));
    state$
      .accountAuthKey$
      .subscribe(accountAuthKey => this.updateState({
        accountAuthKey,
      }));
    state$
      .authKey$
      .subscribe(authKey => this.updateState({
        authKey,
      }));
    state$
      .batch$
      .subscribe(batch => this.updateState({
        batch,
      }));
    state$
      .block$
      .gasPrice$
      .subscribe(gasPrice => this.updateState({
        gasPrice,
      }));
    state$
      .kChannels$
      .pipe(
        map(kChannels => kChannels?.balance),
      )
      .subscribe(kChannelsBalance => this.updateState({
        kChannelsBalance,
      }));

    state$
      .account$
      .address$
      .pipe(
        switchMap(address => from(sdk.subscribeBalance({
          address,
        }))),
        mergeMap(value => value),
      )
      .subscribe(accountBalance => this.updateState({
        accountBalance,
      }));

    state$
      .account$
      .address$
      .pipe(
        switchMap(address => from(
          address
            ? sdk.subscribeAccountDeposit()
            : Promise.resolve(of(null)),
        )),
        mergeMap(value => value),
      )
      .subscribe((accountDeposit: IAccountDeposit) => {
        this.updateState({
          accountDeposit: accountDeposit
            ? accountDeposit.availableAmount
            : null,
        });
      });
  }

  public get state(): IInspectorState {
    return this.state$.getValue();
  }

  public createChangeTabHandler(tab: InspectorTabs): () => void {
    return () => this.changeTab(tab);
  }

  public changeTab(tab: InspectorTabs, toggle = true): void {
    tab = toggle && this.state.tab === tab ? null : tab;

    this.storage.setItem(StorageKeys.inspectorTab, tab);

    this.updateState({
      tab,
    });
  }

  public resetLogs(): void {
    this.updateState({
      logs: [],
    });
  }

  public resetNotifications(): void {
    this.updateState({
      notifications: [],
    });
  }

  private updateState(state: Partial<IInspectorState>): void {
    this.state$.next({
      ...this.state,
      ...state,
    });
  }
}
