import BN from 'bn.js';
import { toHex, toWei } from 'eth-sdk';

export function templateString(value: string): string {
  return value
    ? `'${value}'`
    : 'null';
}

export function templateInt(value: number): string {
  return value
    ? `${value}`
    : 'null';
}

export function templateBN(value: BN, defaultValue: string = null): string {
  const hex = toHex(value, defaultValue);

  return hex
    ? `toBN('${hex}')`
    : 'null';
}

export function templateWei(value: string): string {
  const wei = toWei(value);

  return wei
    ? `toWei('${wei}')`
    : 'null';
}

export function isTrueSet(value: string): boolean {
  return value === 'true';
}
