export enum Screens {
  Default = 'createAccount',

  // account
  CreateAccount = 'createAccount',
  ResetAccount = 'resetAccount',
  GetAccountAuthKeys = 'getAccountAuthKeys',
  GetAccountEvents = 'getAccountEvents',

  // kChannels
  KChannelsAuthenticate = 'authenticate',
  KChannelsCreate = 'channelCreate',
  KChannelsDeposit = 'channelDeposit',
  KChannelsTransact = 'channelTransact',
  KChannelsWithdraw = 'channelWithdraw',

  // payments
  GetAccountDeposit = 'getAccountDeposit',
  SubscribeAccountDeposit = 'subscribeAccountDeposit',
  GetAccountPaymentChannels = 'getAccountPaymentChannels',
  GetPaymentChannel = 'getPaymentChannel',
  UpdatePaymentChannel = 'updatePaymentChannel',

  // authKey
  SignMessage = 'signMessage',
  ExportPrivateKey = 'exportPrivateKey',

  // batch
  BatchMoveFundsToAccountDeposit = 'batchMoveFundsToAccountDeposit',
  BatchCommitAccountPaymentChannel = 'batchCommitAccountPaymentChannel',
  BatchExecuteAccountTransaction = 'batchExecuteAccountTransaction',
  BatchSetAccountAuthKey = 'batchSetAccountAuthKey',
  EstimateBatch = 'estimateBatch',
  SubmitBatch = 'submitBatch',
  ResetBatch = 'resetBatch',

  // utils
  GetBalance = 'getBalance',
  SubscribeBalance = 'subscribeBalance',
}
