import { toChecksumAddress } from 'eth-sdk';
import React from 'react';
import { Code, ContextComponent, Form, TextField, SubmitButton, InspectorTabs } from '../shared';
import {
  templateString,
} from './helpers';

export interface IFormFields {
  address: string;
  token: string;
}

export interface ISubmitFields {
  address: string;
  token: string;
}

export interface IState {
  formFields: IFormFields;
  submitFields: ISubmitFields;
}

const renderCode = ({ address, token }: ISubmitFields) => `
const options = {
  address: ${templateString(address)}, // optional
  token: ${templateString(token)}, // optional
};

sdk
  .getBalance(options)
  .then(balance => console.log('balance', balance))
  .catch(console.error);
`;

export class GetBalance extends ContextComponent<any, IState> {
  private static formFieldsToState(formFields: IFormFields): IState {
    const address = toChecksumAddress(formFields.address);
    const token = toChecksumAddress(formFields.token);

    return {
      formFields,
      submitFields: {
        address,
        token,
      },
    };
  }

  public state: IState = GetBalance.formFieldsToState({
    address: null,
    token: null,
  });

  constructor(props: any) {
    super(props);

    this.formChangeHandler = this.formChangeHandler.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
  }

  public render(): React.ReactNode {
    const { formFields, submitFields } = this.state;

    return (
      <div>
        <h1>getBalance</h1>
        <div>
          <Code data={renderCode(submitFields)} />
          <Form
            onChange={this.formChangeHandler}
            onSubmit={this.formSubmitHandler}
            fields={formFields}
          >
            <TextField
              label="options.address"
              name="address"
            />
            <TextField
              label="options.token"
              name="token"
              buttons={this.tokenFieldButtons}
            />
            <SubmitButton />
          </Form>
        </div>
      </div>
    );
  }

  private formChangeHandler(formFields: IFormFields): void {
    this.setState(
      GetBalance
        .formFieldsToState(formFields),
    );
  }

  private formSubmitHandler(): void {
    this.wrapAsync(async () => {
      const { address, token } = this.state.submitFields;

      const balance = await this
        .sdk
        .getBalance({
          address,
          token,
        });

      this.console.log('balance', balance);

      this
        .inspector
        .changeTab(
          InspectorTabs.Logs,
          false,
        );
    });
  }
}
