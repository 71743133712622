import React from 'react';
import { filter, tap } from 'rxjs/operators';
import { Inspector, Loader, Navigation, StatusBar, Summary, ScreenManager } from './containers';
import { ContextComponent } from './shared';
import styles from './App.module.scss';

export interface IState {
  initialized: boolean;
}

export class App extends ContextComponent<any, IState> {
  public state: IState = {
    initialized: false,
  };

  public componentDidMount(): void {
    this
      .sdk
      .state$
      .initialized$
      .pipe(
        filter(value => value),
        tap(initialized => this.setState({
          initialized,
        })),
        tap(() => this.loader.hide()),
      )
      .subscribe();
  }

  public render(): React.ReactNode {
    const { initialized } = this.state;
    return (
      <>
        <Loader />
        {!initialized ? null : (
          <div className={styles.container}>
            <Navigation />
            <div className={styles.content}>
              <Summary />
              <ScreenManager />
              <Inspector />
            </div>
          </div>
        )}
        <StatusBar />
      </>
    );
  }
}
