import React from 'react';
import { Code, ContextComponent, Form, TextField, SubmitButton, InspectorTabs } from '../shared';
import {
  templateInt,
} from './helpers';

export interface IFormFields {
  page: string;
}

export interface ISubmitFields {
  page: number;
}

export interface IState {
  formFields: IFormFields;
  submitFields: ISubmitFields;
}

const renderCode = ({ page }: ISubmitFields) => `
const page = ${templateInt(page)}; // optional

sdk
  .getAccountPaymentChannels(page)
  .then(accountPaymentChannels => console.log('accountPaymentChannels', accountPaymentChannels))
  .catch(console.error);
`;

export class GetAccountPaymentChannels extends ContextComponent<any, IState> {
  private static formFieldsToState(formFields: IFormFields): IState {
    const page = parseInt(formFields.page);

    return {
      formFields,
      submitFields: {
        page: page > 0 ? page : null,
      },
    };
  }

  public state: IState = GetAccountPaymentChannels.formFieldsToState({
    page: null,
  });

  constructor(props: any) {
    super(props);

    this.formChangeHandler = this.formChangeHandler.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
  }

  public render(): React.ReactNode {
    const { formFields, submitFields } = this.state;

    return (
      <div>
        <h1>getAccountPaymentChannels</h1>
        <div>
          <Code data={renderCode(submitFields)} />
          <Form
            onChange={this.formChangeHandler}
            onSubmit={this.formSubmitHandler}
            fields={formFields}
          >
            <TextField
              label="page"
              name="page"
            />
            <SubmitButton />
          </Form>
        </div>
      </div>
    );
  }

  private formChangeHandler(formFields: IFormFields): void {
    this.setState(
      GetAccountPaymentChannels
        .formFieldsToState(formFields),
    );
  }

  private formSubmitHandler(): void {
    this.wrapAsync(async () => {
      const { page } = this.state.submitFields;

      const accountPaymentChannels = await this
        .sdk
        .getAccountPaymentChannels(page);

      this.console.log('accountPaymentChannels', accountPaymentChannels);

      this
        .inspector
        .changeTab(
          InspectorTabs.Logs,
          false,
        );
    });
  }
}
