import React from 'react';
import { Code, ContextComponent, Form, StorageKeys, SubmitButton } from '../shared';

const renderCode = () => `
sdk
  .resetAccount()
  .catch(console.error);
`;

export class ResetAccount extends ContextComponent {
  constructor(props: any) {
    super(props);

    this.formSubmitHandler = this.formSubmitHandler.bind(this);
  }

  public render(): React.ReactNode {
    return (
      <div>
        <h1>resetAccount</h1>
        <div>
          <Code data={renderCode()} />
          <Form onSubmit={this.formSubmitHandler}>
            <SubmitButton />
          </Form>
        </div>
      </div>
    );
  }

  private formSubmitHandler(): void {
    this.wrapAsync(async () => {
      await this
        .sdk
        .resetAccount();

      this.storage.setItem(StorageKeys.account);
    });
  }
}
