import BN from 'bn.js';
import { randomAddress, toChecksumAddress, toHex, toWei } from 'eth-sdk';
import React from 'react';
import { Code, ContextComponent, Form, InspectorTabs, SubmitButton, TextField } from '../shared';
import { templateBN, templateString } from './helpers';

export interface IFormFields {
  recipient: string;
  value: string;
  data: string;
}

export interface ISubmitFields {
  recipient: string;
  value: BN;
  data: string;
}

export interface IState {
  formFields: IFormFields;
  submitFields: ISubmitFields;
}

const renderCode = ({  recipient, value, data }: ISubmitFields) => `
import { toBN } from 'eth-sdk';

const options = {
  recipient: ${templateString(recipient)}, // if not set, will send to KChannels Contract
  value: ${templateBN(value)},
  data: ${templateString(data)},
};

sdk
  .kChannelsDeposit(options)
  .then(batch => console.log('batch', batch))
  .catch(console.error);
  
// subscribe: sdk.state$.batch$
`;

export class KChannelsDeposit extends ContextComponent<any, IState> {
  private static formFieldsToState(formFields: IFormFields): IState {
    const recipient = toChecksumAddress(formFields.recipient);
    const value = toWei(parseFloat(formFields.value) || 0);
    const data = toHex(formFields.data, '0x', true);

    return {
      formFields,
      submitFields: {
        recipient,
        value,
        data,
      },
    };
  }

  public state: IState = KChannelsDeposit.formFieldsToState({
    recipient: null,
    value: null,
    data: null,
  });

  constructor(props: any) {
    super(props);

    this.formChangeHandler = this.formChangeHandler.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
  }

  public render(): React.ReactNode {
    const { formFields, submitFields } = this.state;

    return (
      <div>
        <h1>kChannelsDeposit</h1>
        <div>
          <Code data={renderCode(submitFields)} />
          <Form
            onChange={this.formChangeHandler}
            onSubmit={this.formSubmitHandler}
            fields={formFields}
          >
            <TextField
              label="options.recipient"
              name="recipient"
              buttons={[
                { title: 'random', onClick: setFieldValue => setFieldValue(randomAddress()) },
                ...this.tokenFieldButtons,
              ]}
            />
            <TextField
              label="options.value (ETH)"
              name="value"
              buttons={[
                { title: '+ 0.100', onClick: setFieldValue => setFieldValue(0.1, 3) },
                { title: '+ 0.010', onClick: setFieldValue => setFieldValue(0.01, 3) },
                { title: '+ 0.001', onClick: setFieldValue => setFieldValue(0.001, 3) },
              ]}
            />
            <TextField
              label="options.data"
              name="data"
            />
            <SubmitButton  />
          </Form>
        </div>
      </div>
    );
  }

  private formChangeHandler(formFields: IFormFields): void {
    this.setState(
      KChannelsDeposit
        .formFieldsToState(formFields),
    );
  }

  private formSubmitHandler(): void {
    this.wrapAsync(async () => {
      const { recipient, value, data } = this.state.submitFields;

      const batch = await this
        .sdk
        .kChannelsDeposit({
          recipient,
          value,
          data,
        });

      this.console.log('batch', batch);

      this
        .inspector
        .changeTab(
          InspectorTabs.Notifications,
          false,
        );
    });
  }
}
