import React from 'react';
import { filter } from 'rxjs/operators';
import { logo } from '../resources';
import { Screens } from '../screens';
import { IScreenState, ContextComponent, prepareClassNames } from '../shared';
import styles from './Navigation.module.scss';

export class Navigation extends ContextComponent<any, IScreenState> {
  public state: IScreenState = null;

  public componentDidMount(): void {
    this.addSubscriptions(
      this
        .screen
        .state$
        .pipe(
          filter(value => !!value),
        )
        .subscribe(state => this.setState(state)),
    );
  }

  public render(): React.ReactNode {
    if (!this.state) {
      return null;
    }

    return (
      <div className={styles.root}>
        <a
          href="https://abridged.io"
          className={styles.logo}
        >
          <img
            src={logo}
            alt={'/ - )'}
          />
        </a>

        <div>
          <span># account</span>
          <div>
            {this.renderSection(Screens.CreateAccount)}
            {this.renderSection(Screens.ResetAccount)}
            {this.renderSection(Screens.GetAccountAuthKeys)}
            {this.renderSection(Screens.GetAccountEvents)}
          </div>
        </div>
        <div>
          <span># batch transactions</span>
          <div>
            {this.renderSection(Screens.BatchExecuteAccountTransaction)}
            {this.renderSection(Screens.BatchSetAccountAuthKey)}
            {this.renderSection(Screens.BatchMoveFundsToAccountDeposit)}
            {this.renderSection(Screens.BatchCommitAccountPaymentChannel)}
            {this.renderSection(Screens.EstimateBatch)}
            {this.renderSection(Screens.SubmitBatch)}
            {this.renderSection(Screens.ResetBatch)}
          </div>
        </div>
        <div>
          <span># p2p payments</span>
          <div>
            {this.renderSection(Screens.GetAccountDeposit)}
            {this.renderSection(Screens.SubscribeAccountDeposit)}
            {this.renderSection(Screens.GetAccountPaymentChannels)}
            {this.renderSection(Screens.GetPaymentChannel)}
            {this.renderSection(Screens.UpdatePaymentChannel)}
          </div>
        </div>
        <div>
          <span># kChannels</span>
          <div>
            {this.renderSection(Screens.KChannelsAuthenticate)}
            {this.renderSection(Screens.KChannelsCreate)}
            {this.renderSection(Screens.KChannelsDeposit)}
            {this.renderSection(Screens.KChannelsTransact)}
            {this.renderSection(Screens.KChannelsWithdraw)}
          </div>
        </div>
        <div>
          <span># utils</span>
          <div>
            {this.renderSection(Screens.SignMessage)}
            {this.renderSection(Screens.ExportPrivateKey)}
            {this.renderSection(Screens.GetBalance)}
            {this.renderSection(Screens.SubscribeBalance)}
          </div>
        </div>
      </div>
    );
  }

  private renderSection(key: string): React.ReactNode {
    let { screen } = this.state;

    if (!Object.values(Screens).includes(screen as any)) {
      screen = Screens.Default;
    }

    return (
      <div>
        <a
          className={prepareClassNames([
            styles.section,
            screen === key && styles.active,
          ])}
          href={`#/${key}`}
          onClick={this.screen.createOpenHandler(key)}
        >
          {key}
        </a>
      </div>
    );
  }
}
