import { BehaviorSubject } from 'rxjs';

export enum ConsoleItemTypes {
  Error = 'Error',
  Log = 'Log',
}

export interface IConsoleItem {
  type: ConsoleItemTypes;
  args: any[];
}

export class Console {
  public item$ = new BehaviorSubject<IConsoleItem>(null);

  constructor() {
    this.log = this.log.bind(this);
    this.error = this.error.bind(this);
  }

  public log(...args: any[]): void {
    this.item$.next({
      args,
      type: ConsoleItemTypes.Log,
    });

    console.log(...args);
  }

  public error(...args: any[]): void {
    this.item$.next({
      args,
      type: ConsoleItemTypes.Error,
    });

    console.error(...args);
  }
}
