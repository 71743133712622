import React from 'react';
import { prepareClassNames } from '../utils';
import styles from './Input.module.scss';

export interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onTextChange?: (value: string) => any;
  onTextSubmit?: () => any;
}

export class Input extends React.Component<IProps> {
  public render(): any {
    const { className, onTextChange, onTextSubmit, ...props } = this.props;

    let onChange: React.ChangeEventHandler<HTMLInputElement>;
    let onKeyPress: React.KeyboardEventHandler<HTMLInputElement>;

    if (onTextChange) {
      onChange = (event) => {
        const { target: { value } } = event;
        onTextChange(value);
      };
    }

    if (onTextSubmit) {
      onKeyPress = (event) => {
        const { charCode } = event;
        if (charCode === 13) {
          onTextSubmit();
        }
      };
    }

    return (
      <input
        className={prepareClassNames([
          styles.root,
          className,
        ])}
        onChange={onChange}
        onKeyPress={onKeyPress}
        {...props}
      />
    );
  }
}
