import React from 'react';
import { filter } from 'rxjs/operators';
import {
  Screens,
  ResetAccount,
  CreateAccount,
  BatchExecuteAccountTransaction,
  BatchSetAccountAuthKey,
  EstimateBatch,
  SubmitBatch,
  GetBalance,
  SubscribeBalance,
  SignMessage,
  ExportPrivateKey,
  ResetBatch,
  GetAccountAuthKeys,
  GetAccountEvents,
  GetAccountDeposit,
  SubscribeAccountDeposit,
  BatchMoveFundsToAccountDeposit,
  GetAccountPaymentChannels,
  GetPaymentChannel,
  UpdatePaymentChannel,
  BatchCommitAccountPaymentChannel,
  KChannelsAuthenticate,
  KChannelsCreate,
  KChannelsDeposit,
  KChannelsTransact,
  KChannelsWithdraw,
} from '../screens';
import { IScreenState, ContextComponent } from '../shared';
import styles from './ScreenManager.module.scss';

export class ScreenManager extends ContextComponent<any, IScreenState> {
  public state: IScreenState = {
    screen: null,
  };

  public componentDidMount(): void {
    this.addSubscriptions(
      this
        .screen
        .state$
        .pipe(
          filter(value => !!value),
        )
        .subscribe(state => this.setState(state)),
    );
  }

  public render(): React.ReactNode {
    if (!this.state) {
      return null;
    }

    let content: React.ReactNode = null;

    let { screen } = this.state;
    if (!Object.values(Screens).includes(screen as any)) {
      screen = Screens.Default;
    }

    switch (screen) {
      case Screens.CreateAccount:
        content = <CreateAccount />;
        break;

      case Screens.ResetAccount:
        content = <ResetAccount />;
        break;

      case Screens.BatchExecuteAccountTransaction:
        content = <BatchExecuteAccountTransaction />;
        break;

      case Screens.BatchSetAccountAuthKey:
        content = <BatchSetAccountAuthKey />;
        break;

      case Screens.EstimateBatch:
        content = <EstimateBatch />;
        break;

      case Screens.SubmitBatch:
        content = <SubmitBatch />;
        break;

      case Screens.GetBalance:
        content = <GetBalance />;
        break;

      case Screens.SubscribeBalance:
        content = <SubscribeBalance />;
        break;

      case Screens.SignMessage:
        content = <SignMessage />;
        break;

      case Screens.ExportPrivateKey:
        content = <ExportPrivateKey />;
        break;

      case Screens.ResetBatch:
        content = <ResetBatch />;
        break;

      case Screens.GetAccountAuthKeys:
        content = <GetAccountAuthKeys />;
        break;

      case Screens.GetAccountEvents:
        content = <GetAccountEvents />;
        break;

      case Screens.GetAccountDeposit:
        content = <GetAccountDeposit />;
        break;

      case Screens.SubscribeAccountDeposit:
        content = <SubscribeAccountDeposit />;
        break;

      case Screens.BatchMoveFundsToAccountDeposit:
        content = <BatchMoveFundsToAccountDeposit />;
        break;

      case Screens.GetAccountPaymentChannels:
        content = <GetAccountPaymentChannels />;
        break;

      case Screens.GetPaymentChannel:
        content = <GetPaymentChannel />;
        break;

      case Screens.UpdatePaymentChannel:
        content = <UpdatePaymentChannel />;
        break;

      case Screens.BatchCommitAccountPaymentChannel:
        content = <BatchCommitAccountPaymentChannel />;
        break;

      case Screens.KChannelsAuthenticate:
        content = <KChannelsAuthenticate />;
        break;

      case Screens.KChannelsCreate:
        content = <KChannelsCreate />;
        break;

      case Screens.KChannelsDeposit:
        content = <KChannelsDeposit />;
        break;

      case Screens.KChannelsTransact:
        content = <KChannelsTransact />;
        break;

      case Screens.KChannelsWithdraw:
        content = <KChannelsWithdraw />;
        break;
    }

    return (
      <div
        className={styles.root}
      >
        {content}
      </div>
    );
  }
}
