import { isHex } from 'eth-sdk';
import React from 'react';
import { Code, ContextComponent, Form, TextField, SubmitButton, InspectorTabs } from '../shared';
import {
  templateString,
} from './helpers';

export interface IFormFields {
  hash: string;
}

export interface ISubmitFields {
  hash: string;
}

export interface IState {
  formFields: IFormFields;
  submitFields: ISubmitFields;
  disabled: boolean;
}

const renderCode = ({ hash }: ISubmitFields) => `
const hash = ${templateString(hash)};

sdk
  .batchCommitAccountPaymentChannel(hash)
  .then(batch => console.log('batch', batch))
  .catch(console.error);
  
// subscribe: sdk.state$.batch$
`;

export class BatchCommitAccountPaymentChannel extends ContextComponent<any, IState> {
  private static formFieldsToState(formFields: IFormFields): IState {
    const hash = isHex(formFields.hash) ? formFields.hash : null;

    return {
      formFields,
      submitFields: {
        hash,
      },
      disabled: !hash,
    };
  }

  public state: IState = BatchCommitAccountPaymentChannel.formFieldsToState({
    hash: null,
  });

  constructor(props: any) {
    super(props);

    this.formChangeHandler = this.formChangeHandler.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
  }

  public render(): React.ReactNode {
    const { formFields, submitFields, disabled } = this.state;

    return (
      <div>
        <h1>batchCommitAccountPaymentChannel</h1>
        <div>
          <Code data={renderCode(submitFields)} />
          <Form
            onChange={this.formChangeHandler}
            onSubmit={this.formSubmitHandler}
            fields={formFields}
          >
            <TextField
              label="hash"
              name="hash"
            />
            <SubmitButton disabled={disabled} />
          </Form>
        </div>
      </div>
    );
  }

  private formChangeHandler(formFields: IFormFields): void {
    this.setState(
      BatchCommitAccountPaymentChannel
        .formFieldsToState(formFields),
    );
  }

  private formSubmitHandler(): void {
    this.wrapAsync(async () => {
      const { hash } = this.state.submitFields;

      const batch = await this
        .sdk
        .batchCommitAccountPaymentChannel(hash);

      this.console.log('batch', batch);

      this
        .inspector
        .changeTab(
          InspectorTabs.Batch,
          false,
        );
    });
  }
}
