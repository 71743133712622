import React from 'react';
import { prepareClassNames } from '../utils';
import styles from './CopyTrigger.module.scss';

export interface IProps {
  title: string;
  text: string;
  className?: string;
}

export class CopyTrigger extends React.Component<IProps, any> {
  private ref: HTMLButtonElement;
  private input: HTMLInputElement;

  constructor(props: IProps) {
    super(props);

    this.clickHandler = this.clickHandler.bind(this);
  }

  public componentDidMount(): void {
    this.input = document.createElement('input');
    this.ref.appendChild(this.input);
  }

  public componentWillUnmount(): void {
  }

  public render(): any {
    const { title, className } = this.props;
    return (
      <button
        className={prepareClassNames([
          styles.root,
          className,
        ])}
        onClick={this.clickHandler}
        ref={ref => this.ref = ref}
      >
        {title}
      </button>
    );
  }

  private clickHandler(): void {
    const { text } = this.props;
    this.input.value = text;
    this.input.select();
    this.input.setSelectionRange(0, 99999);
    document.execCommand('copy');
  }
}
