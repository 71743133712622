import BN from 'bn.js';
import { randomAddress, toWei, toChecksumAddress } from 'eth-sdk';
import React from 'react';
import { Code, ContextComponent, Form, TextField, SubmitButton, InspectorTabs } from '../shared';
import {
  templateBN,
  templateString,
} from './helpers';

export interface IFormFields {
  recipient: string;
  token: string;
  amount: string;
  uidBase: string;
}

export interface ISubmitFields {
  recipient: string;
  token: string;
  amount: BN;
  uidBase: string;
}

export interface IState {
  formFields: IFormFields;
  submitFields: ISubmitFields;
  disabled: boolean;
}

const renderCode = ({ recipient, token, amount, uidBase }: ISubmitFields) => `
import { toBN } from 'eth-sdk';

const options = {
  recipient: ${templateString(recipient)},
  token: ${templateString(token)}, // optional
  amount: ${templateBN(amount)},
  uidBase: ${templateString(uidBase)}, // optional
};

sdk
  .updatePaymentChannel(options)
  .then(paymentChannel => console.log('paymentChannel', paymentChannel))
  .catch(console.error);
`;

export class UpdatePaymentChannel extends ContextComponent<any, IState> {
  private static formFieldsToState(formFields: IFormFields): IState {
    const recipient = toChecksumAddress(formFields.recipient);
    const token = toChecksumAddress(formFields.token);
    const amount = toWei(parseFloat(formFields.amount) || 0);
    const uidBase = formFields.uidBase || null;

    return {
      formFields,
      submitFields: {
        recipient,
        token,
        amount,
        uidBase,
      },
      disabled: !recipient || !amount,
    };
  }

  public state: IState = UpdatePaymentChannel.formFieldsToState({
    recipient: null,
    token: null,
    amount: null,
    uidBase: null,
  });

  constructor(props: any) {
    super(props);

    this.formChangeHandler = this.formChangeHandler.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
  }

  public render(): React.ReactNode {
    const { formFields, submitFields, disabled } = this.state;

    return (
      <div>
        <h1>updatePaymentChannel</h1>
        <div>
          <Code data={renderCode(submitFields)} />
          <Form
            onChange={this.formChangeHandler}
            onSubmit={this.formSubmitHandler}
            fields={formFields}
          >
            <TextField
              label="options.recipient"
              name="recipient"
              buttons={[
                { title: 'random', onClick: setFieldValue => setFieldValue(randomAddress()) },
              ]}
            />
            <TextField
              label="options.token"
              name="token"
              buttons={this.tokenFieldButtons}
            />
            <TextField
              label="options.amount (ETH)"
              name="amount"
              buttons={[
                { title: '+ 0.100', onClick: setFieldValue => setFieldValue(0.1, 3) },
                { title: '+ 0.010', onClick: setFieldValue => setFieldValue(0.01, 3) },
                { title: '+ 0.001', onClick: setFieldValue => setFieldValue(0.001, 3) },
              ]}
            />
            <TextField
              label="options.uidBase"
              name="uidBase"
            />
            <SubmitButton disabled={disabled} />
          </Form>
        </div>
      </div>
    );
  }

  private formChangeHandler(formFields: IFormFields): void {
    this.setState(
      UpdatePaymentChannel
        .formFieldsToState(formFields),
    );
  }

  private formSubmitHandler(): void {
    this.wrapAsync(async () => {
      const { uidBase, amount, recipient, token } = this.state.submitFields;

      const paymentChannel = await this
        .sdk
        .updatePaymentChannel({
          uidBase,
          amount,
          recipient,
          token,
        });

      this.console.log('paymentChannel', paymentChannel);

      this
        .inspector
        .changeTab(
          InspectorTabs.Logs,
          false,
        );
    });
  }
}
