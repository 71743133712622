"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function getNetworkName(version) {
  var result = 'unknown';

  switch (version) {
    case 1:
      result = 'main';
      break;

    case 3:
      result = 'ropsten';
      break;

    case 4:
      result = 'rinkeby';
      break;

    case 5:
      result = 'goerli';
      break;

    case 42:
      result = 'kovan';
      break;

    case 100:
      result = 'xdai';
      break;

    case 4660:
      result = 'local';
      break;
  }

  return result;
}

exports.getNetworkName = getNetworkName;