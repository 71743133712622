import { randomAddress, toChecksumAddress, toWei } from 'eth-sdk';
import React from 'react';
import { Code, ContextComponent, Form, InspectorTabs, SubmitButton, TextField } from '../shared';
import { isTrueSet, templateString, templateWei } from './helpers';

export interface IFormFields {
  recipient: string;
  tokenAddress: string;
  value: string;
  forceExternalTransaction: string;
  subtractFeesFromValue: string;
  searchByDepositAddress: string;
  channelUUID: string;
  channelDefinitionVersion: string;
  zoneClientEndpoint: string;
}

export interface ISubmitFields {
  recipient: string;
  tokenAddress: string;
  value: string;
  forceExternalTransaction: boolean;
  subtractFeesFromValue: boolean;
  searchByDepositAddress: boolean;
  channelUUID: string;
  channelDefinitionVersion: string;
  zoneClientEndpoint: string;
}

export interface IState {
  formFields: IFormFields;
  submitFields: ISubmitFields;
  disabled: boolean;
}

const renderCode = ({ recipient,
                      tokenAddress,
                      value,
                      forceExternalTransaction,
                      subtractFeesFromValue,
                      searchByDepositAddress,
                      channelUUID,
                      channelDefinitionVersion,
                      zoneClientEndpoint}: ISubmitFields) => `
import { toBN } from 'eth-sdk';

const options = {
  recipient: ${templateString(recipient)},
  tokenAddress: ${templateString(tokenAddress)}, // if not set will send ETH
  value: ${templateWei(value)},
  forceExternalTransaction: ${forceExternalTransaction},
  subtractFeesFromValue: ${subtractFeesFromValue},
  searchByDepositAddress: ${searchByDepositAddress},
  channelUUID: ${templateString(channelUUID)}, // if not set gets auth key's channel definition
  channelDefinitionVersion: ${templateString(channelDefinitionVersion)}, // if not set gets auth key's channel definition
  zoneEndpointUrl: ${templateString(zoneClientEndpoint)} // if not set gets auth key's channel definition
};

sdk
  .kChannelsTransact(options)
  .then(transactionInformation => console.log('transactionInformation', transactionInformation))
  .catch(console.error);
`;

export class KChannelsTransact extends ContextComponent<any, IState> {
  private static formFieldsToState(formFields: IFormFields): IState {
    const recipient = toChecksumAddress(formFields.recipient);
    const tokenAddress = toChecksumAddress(formFields.tokenAddress);
    const value = toWei(parseFloat(formFields.value) || 0).toString();
    const forceExternalTransaction = isTrueSet(formFields.forceExternalTransaction);
    const subtractFeesFromValue = isTrueSet(formFields.subtractFeesFromValue);
    const searchByDepositAddress = isTrueSet(formFields.searchByDepositAddress);
    const channelUUID = formFields.channelUUID;
    const channelDefinitionVersion = formFields.channelDefinitionVersion;
    const zoneClientEndpoint = formFields.zoneClientEndpoint;

    return {
      formFields,
      submitFields: {
        recipient,
        tokenAddress,
        value,
        forceExternalTransaction,
        subtractFeesFromValue,
        searchByDepositAddress,
        channelUUID,
        channelDefinitionVersion,
        zoneClientEndpoint,
      },
      disabled: !recipient,
    };
  }

  public state: IState = KChannelsTransact.formFieldsToState({
    recipient: null,
    tokenAddress: null,
    value: null,
    forceExternalTransaction: null,
    subtractFeesFromValue: null,
    searchByDepositAddress: null,
    channelUUID: null,
    channelDefinitionVersion: null,
    zoneClientEndpoint: null,
  });

  constructor(props: any) {
    super(props);

    this.formChangeHandler = this.formChangeHandler.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
  }

  public render(): React.ReactNode {
    const { formFields, submitFields, disabled } = this.state;

    return (
      <div>
        <h1>kChannelsTransact</h1>
        <div>
          <Code data={renderCode(submitFields)} />
          <Form
            onChange={this.formChangeHandler}
            onSubmit={this.formSubmitHandler}
            fields={formFields}
          >
            <TextField
              label="options.recipient"
              name="recipient"
              buttons={[
                { title: 'random', onClick: setFieldValue => setFieldValue(randomAddress()) },
                ...this.tokenFieldButtons,
              ]}
            />
            <TextField
              label="options.tokenAddress"
              name="tokenAddress"
              buttons={[
                { title: 'random', onClick: setFieldValue => setFieldValue(randomAddress()) },
                ...this.tokenFieldButtons,
              ]}
            />
            <TextField
              label="options.value"
              name="value"
              buttons={[
                { title: '+ 0.100', onClick: setFieldValue => setFieldValue(0.1, 3) },
                { title: '+ 0.010', onClick: setFieldValue => setFieldValue(0.01, 3) },
                { title: '+ 0.001', onClick: setFieldValue => setFieldValue(0.001, 3) },
              ]}
            />
            <TextField
              label="options.forceExternalTransaction"
              name="forceExternalTransaction"
            />
            <TextField
              label="options.subtractFeesFromValue"
              name="subtractFeesFromValue"
            />
            <TextField
              label="options.searchByDepositAddress"
              name="searchByDepositAddress"
            />
            <TextField
              label="options.channelUUID"
              name="channelUUID"
            />
            <TextField
              label="options.channelDefinitionVersion"
              name="channelDefinitionVersion"
            />
            <TextField
              label="options.zoneClientEndpoint"
              name="zoneClientEndpoint"
            />
            <SubmitButton disabled={disabled} />
          </Form>
        </div>
      </div>
    );
  }

  private formChangeHandler(formFields: IFormFields): void {
    this.setState(
      KChannelsTransact
        .formFieldsToState(formFields),
    );
  }

  private formSubmitHandler(): void {
    this.wrapAsync(async () => {
      const { recipient, tokenAddress, value, forceExternalTransaction, subtractFeesFromValue,
        searchByDepositAddress, channelUUID, channelDefinitionVersion, zoneClientEndpoint} = this.state.submitFields;

      const transactionInformation = await this
        .sdk
        .kChannelsTransact({
          recipient,
          tokenAddress,
          value,
          forceExternalTransaction,
          subtractFeesFromValue,
          searchByDepositAddress,
          channelUUID,
          channelDefinitionVersion,
          zoneClientEndpoint,
        });

      this.console.log('transactionInformation', transactionInformation);

      this
        .inspector
        .changeTab(
          InspectorTabs.Logs,
          false,
        );
    });
  }
}
