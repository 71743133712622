import { BehaviorSubject } from 'rxjs';

export interface ILoaderState {
  show: boolean;
}

export class Loader {
  public state$ = new BehaviorSubject<ILoaderState>({
    show: true,
  });

  public show(): void {
    this.state$.next({
      show: true,
    });
  }

  public hide(): void {
    this.state$.next({
      show: false,
    });
  }
}
