import React from 'react';
import { prepareClassNames } from '../utils';
import styles from './Button.module.scss';

export interface IProps {
  title: string;
  className?: string,
  disabled?: boolean;
  danger?: boolean;
  onClick?: () => any;
}

export class Button extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props);

    this.clickHandler = this.clickHandler.bind(this);
  }

  public render(): any {
    const { title, className, disabled, danger } = this.props;
    return (
      <button
        className={prepareClassNames([
          styles.root,
          disabled && styles.disabled,
          danger && styles.danger,
          className,
        ])}
        onClick={this.clickHandler}
      >
        {title}
      </button>
    );
  }

  private clickHandler(): void {
    const { onClick, disabled } = this.props;
    if (onClick && !disabled) {
      onClick();
    }
  }
}
