"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MAINNET_SDK_OPTIONS = {
  kChannelsClient: {
    host: 'zone-manager.mainnet.kchannels.io',
    useSsl: true,
    name: 'kChannels MVP',
    version: 1,
    chainId: 1,
    verifyingContract: '0x07Bb837254c411d9527F3B16DF38C38c6374ce61',
    salt: '0x8a102c4640cfefb2eda4ee058d8783d669df911a16140681aeae27bfcb6c93a3'
  },
  platformClient: {
    host: 'eth.txplatform.stage.abridged.rivet.cloud',
    useSsl: true
  },
  relayerClient: {
    host: 'eth.txrelay.stage.abridged.rivet.cloud',
    useSsl: true
  },
  chainId: 1,
  networkVersion: 1
};
exports.ROPSTEN_SDK_OPTIONS = {
  kChannelsClient: {
    host: 'zone-manager.ropsten.kchannels.io',
    useSsl: true,
    name: 'kChannels MVP',
    version: 1,
    chainId: 3,
    verifyingContract: '0x13e43355EA13806E705eb32874DeABfb60B430F9',
    salt: '0x1bca34e48898ea2e1b8b4001e3218fa6bf964f94d126b3d257108a481d91f71b'
  },
  platformClient: {
    host: 'ropsten.txplatform.stage.abridged.rivet.cloud',
    useSsl: true
  },
  relayerClient: {
    host: 'ropsten.txrelay.stage.abridged.rivet.cloud',
    useSsl: true
  },
  chainId: 3,
  networkVersion: 3
};
exports.RINKEBY_SDK_OPTIONS = {
  kChannelsClient: {
    host: 'zone-manager.rinkeby.kchannels.io',
    useSsl: true,
    name: 'kChannels MVP',
    version: 1,
    chainId: 4,
    verifyingContract: '0x6cd7e721D9D13707D3D447235A30DACFDe2e9fe5',
    salt: '0xc4534c0e806db2a735d514924d31aedf0a58131fa433a7e4cc6c99f1b0a5c27a'
  },
  platformClient: {
    host: 'rinkeby.txplatform.stage.abridged.rivet.cloud',
    useSsl: true
  },
  relayerClient: {
    host: 'rinkeby.txrelay.stage.abridged.rivet.cloud',
    useSsl: true
  },
  chainId: 4,
  networkVersion: 4
};
exports.GOERLI_SDK_OPTIONS = {
  platformClient: {
    host: 'goerli.txplatform.stage.abridged.rivet.cloud',
    useSsl: true
  },
  relayerClient: {
    host: 'goerli.txrelay.stage.abridged.rivet.cloud',
    useSsl: true
  },
  chainId: 5,
  networkVersion: 5
};
exports.KOVAN_SDK_OPTIONS = {
  platformClient: {
    host: 'preview-platform.abridged.io',
    useSsl: true
  },
  relayerClient: {
    host: 'preview-relayer.abridged.io',
    useSsl: true
  },
  chainId: 42,
  networkVersion: 42
};
exports.XDAI_SDK_OPTIONS = {
  kChannelsClient: {
    host: 'zone-manager.xdai.kchannels.io',
    useSsl: true,
    name: 'kChannels MVP',
    version: 1,
    chainId: 100,
    verifyingContract: '0x8fb431e06feE86e47b1589a22c6963bA145E3C85',
    salt: '0x6354dbe3f1d532aee9d8b117e359d0fcdbd8ae31c2fd8e95630dd67036e5bfc6'
  },
  platformClient: {
    host: 'poa.txplatform.stage.abridged.rivet.cloud',
    useSsl: true
  },
  relayerClient: {
    host: 'poa.txrelay.stage.abridged.rivet.cloud',
    useSsl: true
  },
  chainId: 100,
  networkVersion: 100
};
exports.LOCAL_SDK_OPTIONS = {
  chainId: 4660,
  networkVersion: 4660
};