import React from 'react';
import { Select } from '../../components';
import { IContextValue } from '../context';
import { AbstractField, IAbstractProps } from './AbstractField';
import styles from './TextField.module.scss';

export interface IProps extends IAbstractProps {
  options: {
    label: string;
    value: string;
  }[];
}

export class SelectField extends AbstractField<IProps> {
  protected renderComponent(contextValue: IContextValue): any {
    const {
      fields,
      changeField,
    } = contextValue;

    const { name, options } = this.props;
    const value = fields[name] || '';

    const onValueChange = (value: string) => {
      changeField(name, value);
    };

    return (
      <Select
        className={styles.root}
        value={value}
        options={options}
        onValueChange={onValueChange}
      />
    );
  }
}
